<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div class="body-content-overlay"></div>

    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input placeholder="Search Lead" v-model="q" />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <div class="dropdown">
          <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="$router.push({ name: 'leads-dashboard' })">
              Reset Sort
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'reverse-assignee' } }">
              Sort Assignee (Reverse)
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'lead-date' } }">
              Sort Lead Date
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'reverse-lead-date' } }">
              Sort Lead Date (Reverse)
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="todo-task-list-wrapper list-group scroll-area">
        <b-overlay :show="loading" rounded="sm" no-fade>
          <div class="todo-list-container">
            <table class="todo-table">
              <thead>
                <tr class="todo-header ">
                  <th class="pr-0 mr-0" style="font-weight: bold;">#</th>
                  <th style="font-weight: bold;">Full Name</th>
                  <th style="font-weight: bold;">Phone</th>
                  <th style="font-weight: bold;">Email</th>
                  <th style="font-weight: bold;">Team</th>
                  <th style="font-weight: bold;">Section</th>
                  <th style="font-weight: bold; white-space: nowrap;">Lead Date</th>
                  <th style="font-weight: bold;">Assignee</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lead, index) in leads" :key="index" class="todo-row">
                  <td class="todo-cell pr-0 mr-0"><b-form-checkbox :checked="lead.isCompleted" @change="updateLeadIsCompleted(lead)" /></td>
                  <td @click="handleLeadClick(lead)" class="todo-cell" style="white-space: nowrap;">{{ lead.firstName }} {{ lead.lastName }}</td>
                  <td @click="handleLeadClick(lead)" class="todo-cell" style="white-space: nowrap;">{{ lead.phone }}</td>
                  <td @click="handleLeadClick(lead)" class="todo-cell" style="white-space: nowrap;">{{ lead.email }}</td>
                  <td class="todo-cell" style="white-space: nowrap;">
                    <div class="avatar-wrapper">
                      <button :style="'background-color:' + resolveTeamVariant(lead.team)" style=" color: #000; font-size: 0.9em; border: none; padding: 3px 8px; border-radius: 6px; cursor: pointer; width:100%">{{ resolveTeamText(lead.team) }}</button>
                    </div>
                  </td>
                  <td class="todo-cell" style="white-space: nowrap;">
                    <button
                      :style="{
                        backgroundColor: getSectionBgColor(lead.section),
                        color: getSectionColor(lead.section),
                      }"
                      style="font-size: 0.9em; border: none; padding: 3px 8px; border-radius: 6px; cursor: pointer; width:100%"
                      :id="'section-controls-' + lead.id.toString()"
                    >
                      {{ lead.section }}
                    </button>

                    <!-- <b-popover :target="'section-controls-' + lead.id.toString()" triggers="click" variant="primary" placement="bottomright" :custom-class="'my-custom-popover'"> -->
                    <b-popover :target="'section-controls-' + lead.id.toString()" triggers="focus" variant="primary" placement="left">
                      <div class="d-flex flex-column">
                        <button
                          v-for="section in leadFilters"
                          :key="section.title"
                          class="mb-50 button-container hoverable-button"
                          @click="changeLeadSection(lead, section, 'section-controls-' + lead.id.toString())"
                          :style="{
                            backgroundColor: section.bgColor,
                            color: section.textColor,
                            'font-size': '0.9em',
                            border: '1px solid #ccc',
                            'box-shadow': '0px 3px 7px rgba(0, 0, 0, 0.1)',
                            padding: '6px',
                            'border-radius': '0px',
                            cursor: 'pointer',
                            width: '100%',
                          }"
                        >
                          {{ section.title }}
                        </button>
                      </div>
                    </b-popover>
                  </td>
                  <td class="todo-cell" style="white-space: nowrap;">{{ formatDate(lead.created_at, { month: 'short', day: 'numeric' }) }}</td>
                  <td class="todo-cell" style="white-space: nowrap;">
                    <div class="avatar-wrapper">
                      <b-avatar v-if="lead.get_assigned_user" :text="getAvatarText(lead.get_assigned_user)" variant="light-primary" size="26" class="mr-25"> </b-avatar>
                      <span v-if="lead.get_assigned_user">{{ lead.get_assigned_user.name }} {{ lead.get_assigned_user.surname }}</span>
                      <span v-else></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="no-results" :class="{ show: !leads.length }">
            <h5>No Items Found</h5>
          </div>
        </b-overlay>
      </vue-perfect-scrollbar>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar :lead-tags="leadTags" :is-lead-handler-sidebar-active.sync="isLeadHandlerSidebarActive" :lead-filters.sync="leadFilters" />
    </portal>

    <!-- lead Handler -->
    <todo-lead-handler-sidebar v-model="isLeadHandlerSidebarActive" :lead-data.sync="leadData" :blank-lead="blankLead" :lead-filters="leadFilters" />
  </div>
</template>

<script>
import store from '@/store';
import { ref, watch, computed, onUnmounted } from '@vue/composition-api';
import { BPopover, VBPopover, BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BFormCheckbox, BBadge, BAvatar } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import draggable from 'vuedraggable';
import TodoLeftSidebar from './TodoLeftSidebar.vue';
import leadStoreModule from './leadStoreModule';
import TodoLeadHandlerSidebar from './TodoLeadHandlerSidebar.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import { getAvatarText, resolveTagVariant, resolveTagText, resolveTeamVariant, resolveTeamText } from './helpers';
import router from '@/router';

export default {
  components: {
    BPopover,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,

    // App
    TodoLeftSidebar,
    TodoLeadHandlerSidebar,
  },
  directives: {
    'b-popover': VBPopover,
  },
  setup() {
    const LEAD_APP_STORE_MODULE_NAME = 'lead';
    // Register module
    if (!store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.registerModule(LEAD_APP_STORE_MODULE_NAME, leadStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.unregisterModule(LEAD_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const leadTags = [
      { title: 'EXCEL', color: 'success', route: { name: 'leads-dashboard-team', params: { team: 'EXCEL' } } },
      { title: 'WEB', color: 'primary', route: { name: 'leads-dashboard-team', params: { team: 'WEB' } } },
    ];

    const blankLead = {
      id: null,
      assignedUser: null,
      userId: null,
      firstName: null,
      lastName: null,
      source: 'PORTAL',
      email: null,
      phone: null,
      method: null,
      message: null,
      section: null,
      team: null,
      section: 'New Leads',
      status: 'A',
    };

    const leadData = JSON.parse(JSON.stringify(blankLead));

    return {
      blankLead,
      leadData,
      perfectScrollbarSettings,
      searchQuery: '',
      leads: [],
      leadTags,
      isLeadHandlerSidebarActive: false,
      loading: true,
      sortOptions: ['latest', 'title-asc', 'title-desc', 'assignee', 'due-date'],
      leadFilters: [],
      q: null,

      popoverShow: false,
    };
  },

  created() {
    this.fetchLeads();
  },

  watch: {
    isLeadHandlerSidebarActive(newVal) {
      if (newVal) {
      } else {
        this.fetchLeads();
      }
    },

    '$route.params.filter'(newFilter, oldFilter) {
      this.q = null;
      this.fetchLeads(null, newFilter);
    },

    '$route.params.team'(newTeam, oldTeam) {
      if (newTeam != undefined && newTeam != null) {
        this.q = null;
        this.fetchLeads(null, null, newTeam);
      }
    },

    '$route.query.sort'(newSort, oldSort) {
      if (newSort != undefined && newSort != null) {
        this.fetchLeads(null, null, null, newSort);
      }
    },

    q: _.debounce(function(val) {
      this.fetchLeads(val);
    }, 500),
  },

  methods: {
    fetchLeads(q = null, filter = null, team = null, sortBy = null) {
      filter = filter ?? this.$route.params.filter;
      team = team ?? this.$route.params.team;
      q = q ?? this.q;
      sortBy = sortBy ?? this.$route.query.sort;

      this.loading = true;
      store
        .dispatch('lead/fetchLeads', {
          q: q,
          filter: filter,
          team: team,
          sortBy: sortBy,
        })
        .then((res) => {
          this.leads = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          this.loading = false;
          console.log(error);
        });
    },

    updateLeadIsCompleted(val) {
      this.loading = true;
      store
        .dispatch('lead/updateLeadIsCompleted', val)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Lead was updated successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.fetchLeads();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    showSectionControlsPopover(targetLead, lead) {
      this.$root.$emit('bv::show::popover', targetLead);
    },

    handleLeadClick(val) {
      this.leadData = val;
      this.isLeadHandlerSidebarActive = true;
    },

    changeLeadSection(lead, section, targetLead) {
      const tempData = {
        id: lead.id,
        section: section.title,
      };

      this.loading = true;
      store
        .dispatch('lead/updateSection', tempData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Lead was updated successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.$root.$emit('bv::hide::popover', targetLead);
          // router.push({ name: 'leads-dashboard' });
          this.fetchLeads();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    formatDate(value, formatting = { day: 'numeric', month: 'short', year: 'numeric' }) {
      if (!value) return value;
      const date = new Date(value);
      const day = date
        .getDate()
        .toString()
        .padStart(2, '0');
      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date); // Kısa ay adını alır
      const year = date
        .getFullYear()
        .toString()
        .slice(-2);
      return `${day} ${month}  ${year}`;
    },

    getSectionColor(val) {
      const section = this.leadFilters.find((section) => section.title === val);
      return section ? section.textColor : '#FFFFFF';
    },

    getSectionBgColor(val) {
      const section = this.leadFilters.find((section) => section.title === val);
      return section ? section.bgColor : '#000000';
    },

    getAvatarText,
    resolveTagVariant,
    resolveTagText,
    resolveTeamVariant,
    resolveTeamText,
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-todo.scss';
</style>

<style lang="scss" scoped>
.todo-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.todo-header {
  background-color: #f5f5f5;
}

.todo-header th,
.todo-cell {
  padding: 7px;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.todo-cell .avatar-wrapper {
  display: flex;
  align-items: center;
}

.no-results {
  text-align: center;
  padding: 20px;
}

.no-results.show {
  display: block;
}

.todo-header th {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 2;
}

.todo-row:hover {
  background-color: #f2f2f2;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.hoverable-button:hover {
  background-color: #ebebeb !important;
  color: black !important;
  transition: background-color 0.3s;
}
</style>

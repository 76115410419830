<template>
  <b-sidebar id="sidebar-lead-handler" sidebar-class="sidebar-lg" @shown="openSidebar()" :visible="isLeadHandlerSidebarActive" bg-variant="white" shadow backdrop no-header right @change="(val) => $emit('update:is-lead-handler-sidebar-active', val)" @hidden="clearLeadData()">
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 v-if="leadData.id" class="mb-0">
        Update Lead
      </h5>

      <h5 v-else class="mb-0">
        Add Lead
      </h5>
      <div>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="closeSidebar(false)" />
      </div>
    </div>

    <b-tabs class="mt-2" fill>
      <b-tab title="Form" active>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver" style="position: relative;">
          <!-- Form -->
          <b-form @submit.prevent="handleSubmit(saveLead)">
            <div class="mt-2">
              <b-col cols="12" class="mb-1">
                <validation-provider name="Developer Team*" #default="{ errors }" rules="required">
                  <v-select v-model="leadData.team" :options="teams" label="title" :reduce="(val) => val.id" class="small-v-select" :clearable="false" placeholder="Choose a Team"></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="12" class="mb-1">
                <validation-provider name="Assigned User" #default="{ errors }" rules="required">
                  <v-select v-model="leadData.assignedUser" :taggable="true" :options="users" label="value" class="small-v-select" :value="leadData.assignedUser" :reduce="(val) => val.assignedUser" :clearable="false" placeholder="Choose a User"> </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- name -->
              <b-col cols="12" class="mb-1">
                <validation-provider name="Name*" #default="{ errors }" rules="required">
                  <b-form-input v-model="leadData.firstName" id="h-name" placeholder="Name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <!-- lastname -->
              <b-col cols="12" class="mb-1">
                <b-form-input v-model="leadData.lastName" id="h-lastname" placeholder="Lastname" />
              </b-col>

              <!-- email -->
              <b-col cols="12" class="mb-1">
                <validation-provider name="Email" #default="{ errors }" rules="email">
                  <b-form-input v-model="leadData.email" id="d-email" placeholder="Email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- phone -->
              <b-col cols="12" class="mb-1">
                <b-form-input v-model="leadData.phone" id="d-phone" placeholder="Phone" />
              </b-col>

              <!-- Preferred Contact Section -->
              <b-col cols="12" class="mb-1" v-if="leadData.id">
                <v-select v-model="leadData.section" :options="leadFilters" label="title" class="small-v-select" :reduce="(val) => val.title" :clearable="false"> </v-select>
              </b-col>

              <!-- Description -->
              <b-col cols="12" class="mb-1">
                <quill-editor id="quil-content" v-model="leadData.message" :options="editorOption" />
                <div id="quill-toolbar" class="d-flex justify-content-end">
                  <button class="ql-bold"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-link"></button>
                </div>
              </b-col>
            </div>

            <!-- Form Actions -->
            <div class="d-flex justify-content-end mt-2 mb-5">
              <b-button variant="primary" class="mr-2" type="submit">
                {{ leadData.id ? 'Update' : 'Add ' }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-tab>

      <b-tab title="Comments" v-if="leadData.id" class="tab-content">
        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scroll-area" :style="{ height: scrollAreaHeight + 'px' }">
          <b-overlay :show="commentLoading" rounded="sm">
            <div id="comment-section">
              <div v-if="moreCommentsAvailable" class="text-center mb-2">
                <a @click="showMoreComments" class="more-comments-link">{{ comments.length - 3 }} more Comments</a>
              </div>

              <div v-if="!moreCommentsAvailable && comments.length > 3" class="text-center mb-2">
                <a @click="visibleCommentsCount = 3" class="more-comments-link">Hide Earlier Comments</a>
              </div>

              <div class="comment" @dblclick="editComment(comment)" v-for="comment in visibleComments" :key="comment.id">
                <div class="comment-header">
                  <div class="comment-info">
                    <strong>{{ comment.full_name }}</strong>
                  </div>
                  <div class="comment-time">
                    <span v-b-tooltip.hover.top="formatDateTime(comment.created_at)">{{ formatTimeAgo(comment.created_at, comment.updated_at) }}</span>
                  </div>
                </div>
                <!-- v-if="isAddComment" -->
                <p v-html="comment.comment"></p>

                <!-- <div class="fix-comment" v-else>
                  <quill-editor id="quil-content" style="background-color: white;" v-model="tempComment" :options="commentOption" />

                  <div id="comment-toolbar" class="d-flex justify-content-end">
                    <button class="ql-bold"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-link"></button>
                    <b-button v-if="tempComment != null && tempComment != ''" @click="saveComment(leadData.id)" variant="primary" style="width: 144px;">
                      Update Comment
                    </b-button>
                  </div>
                </div> -->
              </div>
            </div>
          </b-overlay>
        </vue-perfect-scrollbar>

        <div class="fix-comment">
          <quill-editor id="quil-content" style="background-color: white;" v-model="tempComment" :options="commentOption" />

          <div id="comment-toolbar" class="d-flex justify-content-end">
            <button class="ql-bold"></button>
            <button class="ql-underline"></button>
            <button class="ql-link"></button>
            <b-button v-if="tempComment != null && tempComment != ''" @click="saveComment(leadData.id)" variant="primary" style="width: 72px;">
              Comment
            </b-button>
          </div>
        </div>
      </b-tab>

      <b-tab title="All activity" v-if="leadData.id">
        <div id="activity-section">
          aaaa
        </div>
      </b-tab>

      <b-tab title="Files" v-if="leadData.id">
        <div id="files-section">
          <b-card v-if="leadData != [] && leadData.get_files.length > 0">
            <span v-for="item in leadData.get_files" :key="item.id">
              <a style="text-decoration: none;" target="_blank" :href="leadFileURL + item.fileLink">
                <b-img :src="require('@/assets/images/file.png')" height="auto" width="20" class="mr-1" style="float: left;" />
                <!-- <small>{{ dateFormat(item.created_at) }}</small> -->

                <span style="font-size: 0.8em;" :href="leadFileURL + item.fileLink">{{ item.fileName }}</span>

                <hr />
              </a>
            </span>
          </b-card>
        </div>
      </b-tab>
    </b-tabs>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BTabs, BTab, VBTooltip } from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, url } from '@validations';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,
    VuePerfectScrollbar,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isLeadHandlerSidebarActive',
    event: 'update:is-lead-handler-sidebar-active',
  },
  props: {
    isLeadHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },

    leadData: {
      type: Object,
      required: false,
    },

    blankLead: {
      type: Object,
      required: true,
    },
    leadFilters: {
      type: Array,
      required: false,
    },
  },

  data() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your message',
    };

    const commentOption = {
      modules: {
        toolbar: '#comment-toolbar',
      },
      placeholder: 'Add a comment',
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    return {
      editorOption,
      commentOption,
      required,
      email,
      url,
      methods: ['PHONE', 'EMAIL', 'SMS', 'LETTER', 'WEBSITE'],
      tempComment: null,
      visibleCommentsCount: 3,
      showCommentToolbar: false,
      teams: [
        {
          title: 'EXCEL TEAM',
          id: 'EXCEL',
        },
        {
          title: 'WEB TEAM',
          id: 'WEB',
        },
        {
          title: 'OTHER',
          id: 'OTHER',
        },
      ],
      users: [],
      comments: [],
      commentLoading: true,
      leadFileURL: store.state.app.leadFileURL,
      perfectScrollbarSettings,
      scrollAreaHeight: 500,
      isAddComment: true,
    };
  },

  created() {
    this.getUsers();
  },

  computed: {
    // Görünen yorumları filtreleyin
    visibleComments() {
      return this.comments.slice(0, this.visibleCommentsCount).reverse();
    },
    // Daha fazla yorum mevcut mu kontrol edin
    moreCommentsAvailable() {
      return this.comments.length > this.visibleCommentsCount;
    },
  },

  methods: {
    getUsers() {
      store
        .dispatch('lead/fetchUsers', [])
        .then((res) => {
          res.data.user.forEach((element) => {
            if (element != null) {
              this.users.push({
                assignedUser: Number(element.id),
                value: String(element.name + ' ' + element.surname),
              });
            }
          });
          this.users.push({
            assignedUser: Number(0),
            value: 'All Users',
          });

          this.users = this.users.reverse();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    editComment(val) {
      console.log(val);
      this.isAddComment = false;
    },

    calculateScrollAreaHeight(val) {
      const commentTabContent = document.querySelector('#sidebar-lead-handler');

      if (commentTabContent) {
        this.scrollAreaHeight = commentTabContent.clientHeight - 250;
      }
    },

    clearLeadData() {
      this.$emit('update:lead-data', JSON.parse(JSON.stringify(this.blankLead)));

      this.$refs.refFormObserver.reset();

      this.visibleCommentsCount = 3;
    },

    closeSidebar(val) {
      this.$emit('update:is-lead-handler-sidebar-active', val);
    },

    openSidebar(val) {
      this.listComments();
    },

    listComments() {
      this.commentLoading = true;
      store
        .dispatch('lead/listLeadComments', { id: this.leadData.id })
        .then((res) => {
          this.comments = res.data;
          this.commentLoading = false;
          this.calculateScrollAreaHeight();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });

          this.commentLoading = true;
        });
    },

    showMoreComments() {
      this.visibleCommentsCount = this.comments.length;
      this.calculateScrollAreaHeight();
    },

    saveLead() {
      if (this.leadData.id != null) {
        store.dispatch('lead/updateLead', this.leadData).then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Lead has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.$emit('update:is-lead-handler-sidebar-active', false);
        });
      } else {
        this.leadData.status = 'B';
        store
          .dispatch('lead/saveLead', this.leadData)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Lead was added successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });

            this.$emit('update:is-lead-handler-sidebar-active', false);
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      }
    },

    saveComment(val) {
      store
        .dispatch('lead/saveLeadComment', { leadId: val, comment: this.tempComment })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Lead comment was added successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.tempComment = null;

          this.listComments();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    formatTimeAgo(dateTimeString, updatedDateTimeString) {
      const now = new Date();
      const date = new Date(dateTimeString);
      const updatedDate = new Date(updatedDateTimeString);
      const diffInMilliseconds = now - date;
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInSeconds < 15) {
        return `Just now`;
      } else if (diffInSeconds >= 15 && diffInSeconds < 60) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago${edited}`;
      } else if (diffInMinutes < 60) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago${edited}`;
      } else if (diffInHours < 24) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago${edited}`;
      } else {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago${edited}`;
      }
    },

    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return dateTime.toLocaleDateString('en-UK', options);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  + #comment-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#comment-section {
  // max-height: 600px;
  // overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

#comment-section::-webkit-scrollbar {
  width: 8px; /* Scroll çubuğu genişliği */
}

#comment-section::-webkit-scrollbar-thumb {
  background-color: #333; /* Scroll çubuğu rengi */
  border-radius: 0px; /* Scroll çubuğu kenar yuvarlaklığı */
}

#comment-section::-webkit-scrollbar-thumb:hover {
  background-color: #444; /* Scroll çubuğu üzerine gelindiğinde rengi */
}

#comment-section::-webkit-scrollbar-track {
  background-color: transparent; /* Scroll çubuğu izleme alanı rengi */
}

.comment-info {
  flex-grow: 1; /* Bilgi alanını genişletir, avatar/simge ile kalan alanı doldurur */
}

.comment-time {
  color: #777;
  font-size: 0.8em;
  margin-right: 1em;
}

#activity-section {
  background-color: #f8f9fa;
  padding: 20px;
}

#files-section {
  background-color: #f8f9fa;
  padding: 20px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px; /* Yorum başlığı ile içerik arasındaki boşluk */
  font-weight: bold;
}

.comment + .comment {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

#comment-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more-comments-link {
  color: #007bff;
  cursor: pointer;
}

.more-comments-link:hover {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

#sidebar-lead-handler {
  position: relative;
}

.fix-comment {
  position: fixed;
  background-color: #f8f9fa;
  bottom: 0;
  width: 100%;
  padding: 20px;
}
</style>

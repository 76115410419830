// helpers.js

export function getAvatarText(user) {
  return user ? user.name[0] + user.surname[0] : 'NA';
}

export function resolveTagVariant(tag) {
  const variants = {
    0: 'secondary',
    1: 'warning',
    2: 'primary',
    3: 'success',
    4: 'info',
  };
  return variants[tag] || 'primary';
}

export function resolveTagText(tag) {
  const texts = {
    A: 'Unassigned',
    B: 'Open',
    C: 'In Progress',
    D: 'Completed',
    E: 'Created Quote',
    J: 'Junk',
    P: 'Lost',
    F: 'Win',
  };
  return texts[tag] || 'primary';
}

export function resolveTeamVariant(tag) {
  const variants = {
    EXCEL: '#77e856',
    WEB: '#4a73e5',
    OTHER: '#80878f',
  };
  return variants[tag] || 'N/A';
}

export function resolveTeamText(tag) {
  const texts = {
    EXCEL: 'EXCEL',
    WEB: 'WEB',
    OTHER: 'OTHER',
  };
  return texts[tag] || 'N/A';
}

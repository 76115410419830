<template>
  <div>
    <div class="color-row" v-for="(row, rowIndex) in colorRows" :key="rowIndex">
      <div v-for="(color, colorIndex) in row" :key="colorIndex" class="color-item">
        <button type="button" :style="'background-color: #' + color" @click="selectColor(colors[colorIndex + rowIndex * rowNumber], color)">
          <span v-if="isSelectedColor(color)" class="tick-icon" :style="'color: #' + colors[colorIndex + rowIndex * rowNumber]">&#10004;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink,
  },

  data() {
    return {
      backgroundColors: ['c7c4c4', 'f06a6a', 'ec8d71', 'f1bd6c', 'f8df72', 'b3df97', '83c9a9', '4ecbc4', '9ee7e3', '4573d2', 'a69ff3', 'cd95ea', 'f9aaef', 'f26fb2', 'fc979a', '6d6e6f'],
      colors: ['000000', '000000', '000000', '000000', '000000', '000000', '000000', '000000', '000000', 'FFFFFF', '000000', '000000', '000000', '000000', '000000', 'FFFFFF'],
      colorRows: [],
      localSelectedBgColor: '',
    };
  },

  props: {
    rowNumber: {
      type: Number,
      default: 8,
      required: false,
    },
  },

  mounted() {
    this.colorRows = this.chunkArray(this.backgroundColors, this.rowNumber);
  },

  methods: {
    isSelectedColor(bgColor) {
      return this.localSelectedBgColor === bgColor;
    },

    selectColor(textColor, bgColor) {
      this.localSelectedBgColor = bgColor;
      this.$emit('update:selected-color', textColor, bgColor);
    },

    chunkArray(arr, size) {
      let chunked = [];
      for (let i = 0; i < arr.length; i += size) {
        chunked.push(arr.slice(i, i + size));
      }
      return chunked;
    },
  },
};
</script>

<style scoped>
.color-row {
  display: flex;
  justify-content: center;
}

.color-item {
  margin: 5px;
}

button {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tick-icon {
  font-size: 0.825em;
  position: absolute;
}
</style>
